.active {
  background-color: #36404a;
}

.lista-utenti ul li:not(.active):hover {
  background-color: #36404a;
  transition: 0.7s;
}

.userChat {
  flex: 1;
  /*background-color: #262E35;*/
}

.received {
  background-color: #36404a;
  margin-bottom: 10px;
  max-width: 70%;
  align-self: flex-start;
  border-radius: 10px;
  color: white;
}
.sent {
  background-color: #576878;
  margin-bottom: 10px;
  max-width: 70%;
  align-self: flex-end;
  border-radius: 10px;
  color: #fff;
}

.notify {
  color: #fcb044;
  font-size: x-small;
  border-radius: 50%;
  animation: pulse 2s infinite;
}

.chat {
  overflow-y: auto;
}
.bin {
  display: flex;
  align-items: center;
  transition: 0.5s;
}
.bin:hover {
  transform: scale(1.2);
}

.zIndezTop {
  z-index: 99999999;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(244, 157, 22, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0.4);
    box-shadow: 0 0 0 0 rgba(244, 157, 22, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(244, 157, 22, 0);
    box-shadow: 0 0 0 10px rgba(244, 157, 22, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
    box-shadow: 0 0 0 0 rgba(244, 157, 22, 0);
  }
}

.table .table_head {
  background-color: #fafafb;
}

.glass_background {
  background: rgba(0, 0, 0, 0.65);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.sidebar {
  width: 240px;
  border-radius: 16px;
  margin: 0 20px;
}

.cursor_pointer {
  cursor: pointer;
}

.pricing {
  background: #007bff;
  /* background: linear-gradient(to right, #0062E6, #33AEFF); */
  background: linear-gradient(to right, #1a1a1a, #4d7087);
}

.pricing .card {
  border: 1px solid rgb(255, 255, 255);
  border-radius: 10px;
  transition: all 0.5s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
}
.card-body {
  z-index: 9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.btn-pricing {
  background-color: rgb(54, 102, 154);
  color: #ffffff;
}

.btn-pricing:hover {
  background-color: rgb(54, 102, 154);
  color: #ffffff;
}

/* .card-body h2, .card-body h3, .card-body h4, .card-body h5, .card-body h6, .card-body p {
  color: #fff;
} */

/* Hover Effects on Card */ 
  
@media (min-width: 769px) and (max-width: 1024px) {
  .pricing .card:hover {
    transform: scale(1.05);
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card:hover .btn {
    opacity: 1;
  }

}

.border-radius-16 {
  border-radius: 16px;
}

.chat_container {
  display: flex;
  width: 95%;
  height: 98%;
  margin: auto;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.plus_button button {
  padding: 10px;
  color: white;
}

.plus_button .btn:focus {
  outline: none !important;
}

.dashboard_box {
  min-height: 100px;
  max-height: 150px;
  height: 100%;
  overflow-x: scroll;
}
.css-16ac5r2-MuiButtonBase-root-MuiListItemButton-root,
.css-1uwabd6 {
  padding: 2px 20px !important;
}
